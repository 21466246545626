import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { FormGroup } from '@angular/forms';
import { convertFormToFileFormDTOArray } from '../../../../shared/dto/file.dto';
import { MeetingActionPlanStatusUpdateFormDTO } from '../dto/meeting-action-plan-status-update.dto';

@Injectable({
  providedIn: 'root',
})
export class MeetingActionPlanStatusUpdateConversion {
  private lang: string;

  constructor(private currentUserPreferenceStore: CurrentUserPreferenceStore) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  formToUpdateStatusReq(formGroup: FormGroup): MeetingActionPlanStatusUpdateFormDTO {
    return {
      id: formGroup.get('id')?.value,
      action_plan_status_id: parseInt(formGroup.get('Status')?.value, 10),
      comment: formGroup.get('Comment')?.value,
      percentage: parseInt(formGroup.get('Percentage')?.value, 10),
      documents: convertFormToFileFormDTOArray(formGroup),
    };
  }
}
