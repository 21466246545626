import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IStatusService } from '../../../../core/interfaces/IStatusService.interface';
import { environment } from '../../../../../environments/environment';
import { MeetingActionPlanStatusUpdateDTO, MeetingActionPlanStatusUpdateFormDTO } from '../dto/meeting-action-plan-status-update.dto';

@Injectable({
  providedIn: 'root',
})
export class MeetingActionPlanStatusUpdateService implements IStatusService {
  apiUrl = environment.apiUrl + '/meetings';

  constructor(protected http: HttpClient) {}

  getLatestStatus(meetingId: number, actionPlanId: number): Observable<any> {
    const url = `${this.apiUrl}/${meetingId}/action-plans/${actionPlanId}/status-updates/latest`;
    return this.http.get<any>(url);
  }

  listStatusUpdatesHistory(
    meetingId: number,
    actionPlanId: number
  ): Observable<MeetingActionPlanStatusUpdateDTO[]> {
    let params = new HttpParams();
    const url = `${this.apiUrl}/${meetingId}/action-plans/${actionPlanId}/status-updates`;
    return this.http.get<MeetingActionPlanStatusUpdateDTO[]>(
        url,
      { params }
    );
  }

  createStatusUpdate(
    meetingId: number,
    item: MeetingActionPlanStatusUpdateFormDTO,
    actionPlanId: number
  ): Observable<MeetingActionPlanStatusUpdateFormDTO> {

    const url = `${this.apiUrl}/${meetingId}/action-plans/${actionPlanId}/status-updates`;
    return this.http.post<MeetingActionPlanStatusUpdateFormDTO>(
      url,
      item
    );
  }

  getStatusUpdateByHistoryId(
    meetingId: number,
    updateId: number
  ): Observable<any> {
    throw new Error('Method not required.');
  }

  updateStatusUpdateByHistoryId(
    meetingId: number,
    historyId: number,
    item: MeetingActionPlanStatusUpdateFormDTO,
    actionPlanId: number
  ): Observable<MeetingActionPlanStatusUpdateFormDTO> {

    const url = `${this.apiUrl}/${meetingId}/action-plans/${actionPlanId}/status-updates/${historyId}`;
    return this.http.put<MeetingActionPlanStatusUpdateFormDTO>(
        url,
        item
    );
  }
}
